import React from 'react';
import PropTypes from 'prop-types';

const GalaxyBackground = ({ className }) => {
  const style = {
    height: '100vh',
    width: '100%',
    backgroundImage: 'url(/images/bkg_galaxy@2x.png)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };
  return (
    <div className={className} style={style} />
  );
};

GalaxyBackground.propTypes = {
  className: PropTypes.string,
};

GalaxyBackground.defaultProps = {
  className: '',
};

export default GalaxyBackground;

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { translatedConnect } from '../../lib/utils';
import MaxWidthWrapper from '../MaxWidthWrapper';
import styles from './TitleBlade.module.css';

const TitleBlade = ({ t, className }) => {
  const imgSrc = '/images/img_laptopGrouping@2x.png';
  return (
    <MaxWidthWrapper className={cx(styles.root, className)}>
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <img src={imgSrc} alt="Intel Laptop Grouping" />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <h2>{t('titleBlade.text')}</h2>
          </Col>
        </Row>
      </Grid>
    </MaxWidthWrapper>
  );
};

TitleBlade.propTypes = {
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
};

TitleBlade.defaultProps = {
  className: '',
};

export default translatedConnect(TitleBlade);

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { translatedConnect } from '../../lib/utils';
import MaxWidthWrapper from '../MaxWidthWrapper';
import styles from './SuccessfulBlade.module.css';

const SuccessfulBlade = ({ t, className }) => (
  <div className={cx(styles.root, className)}>
    <MaxWidthWrapper maxWidth="1120px">
      <Grid fluid>
        <Row className={cx(styles.headerRow)}>
          <Col xs={12}>
            <h3 className={styles.header}>{t('successfulBlade.header')}</h3>
          </Col>
        </Row>
        <Row className={cx(styles.bodyRow)}>
          <Col xs={12} md={6} className={cx(styles.column)}>
            <div>
              <h3>{t('successfulBlade.left.header')}</h3>
              <ul>
                <li>{t('successfulBlade.left.item1')}</li>
                <li>{t('successfulBlade.left.item2')}</li>
                <li>{t('successfulBlade.left.item3')}</li>
                <li>{t('successfulBlade.left.item4')}</li>
                <li>{t('successfulBlade.left.item5')}</li>
              </ul>
            </div>
          </Col>
          <Col xs={12} md={6} className={cx(styles.column)}>
            <div>
              <h3>{t('successfulBlade.right.header')}</h3>
              <ul>
                <li>{t('successfulBlade.right.item1')}</li>
                <li>{t('successfulBlade.right.item2')}</li>
                <li>{t('successfulBlade.right.item3')}</li>
                <li>{t('successfulBlade.right.item4')}</li>
              </ul>
            </div>
          </Col>
        </Row>
      </Grid>
    </MaxWidthWrapper>
  </div>
);

SuccessfulBlade.propTypes = {
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
};

SuccessfulBlade.defaultProps = {
  className: '',
};

export default translatedConnect(SuccessfulBlade);

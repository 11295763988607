import React from 'react';

import Plx from 'react-plx';
import styles from './StarLayer.module.css';

const getDocumentWidth = () => {
  if (typeof document !== 'undefined') {
    return document.body.clientWidth;
  }
  return 1280;
};

// Naively checking clientWidth once, should be fine for this

const StarLayer = () => {
  const src = '/images/bkg_stars@2x.png';
  const parallaxData = [
    {
      start: '0%',
      duration: '100%',
      properties: [
        {
          startValue: 0,
          endValue: getDocumentWidth() > 543 ? 80 : 300,
          unit: '%',
          property: 'backgroundPositionY',
        },
      ],
    },
  ];
  const style = {
    backgroundImage: `url(${src})`,
  };

  return (
    <Plx
      style={style}
      className={styles.root}
      parallaxData={parallaxData}
    />
  );
};

export default StarLayer;

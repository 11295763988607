import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { translatedConnect } from '../../lib/utils';
import MaxWidthWrapper from '../MaxWidthWrapper';
import GetStartedButton from '../GetStartedButton';
import styles from './CompellingBlade.module.css';

const CompellingBlade = ({ t, className }) => {
  const imgSrc = '/images/img_compellingTitles@2x.png';
  return (
    <MaxWidthWrapper className={cx(styles.root, className)}>
      <Grid fluid>
        <Row>
          <Col xs={12} md={6} className={cx(styles.textColumn, 'last-xs first-md')}>
            <div>
              <h3 dangerouslySetInnerHTML={{ __html: t('compellingBlade.header') }} />
              <ul>
                <li>{t('compellingBlade.item1')}</li>
                <li>{t('compellingBlade.item2')}</li>
                <li>{t('compellingBlade.item3')}</li>
              </ul>
              <GetStartedButton />
            </div>
          </Col>
          <Col xs={12} md={6}>
            <img src={imgSrc} alt="Complimentary Software" />
          </Col>
        </Row>
      </Grid>
    </MaxWidthWrapper>
  );
};

CompellingBlade.propTypes = {
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
};

CompellingBlade.defaultProps = {
  className: '',
};

export default translatedConnect(CompellingBlade);

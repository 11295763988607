/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import unorphan from 'unorphan';
import smoothscroll from 'smoothscroll-polyfill';
import { translatedConnect } from '../lib/utils';
import styles from './Main.module.css';
import Layout from '../components/Layout';
import Header from '../components/Header';
import GalaxyBackground from '../components/GalaxyBackground';
import StarLayer from '../components/StarLayer';
import TitleBlade from '../components/TitleBlade';
import VideoBlade from '../components/VideoBlade';
import SoftwareBlade from '../components/SoftwareBlade';
import SalesBlade from '../components/SalesBlade';
import CompellingBlade from '../components/CompellingBlade';
import FullServiceBlade from '../components/FullServiceBlade';
import AdvantageBanner from '../components/AdvantageBanner';
import AdvantageBlade from '../components/AdvantageBlade';
import SuccessfulBlade from '../components/SuccessfulBlade';
import StarterBlade from '../components/StarterBlade';


class Page extends Component {
  componentDidMount() {
    // correct text orphans
    const list = ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'li'];
    setTimeout(() => list.forEach(e => unorphan(e)));

    // polyfill smooth scroll for Safari/IE
    smoothscroll.polyfill();
  }

  render() {
    const { t } = this.props;
    return (
      <div className={styles.root}>
        <Layout>
          <Helmet>
            <title>{t('meta.title')}</title>
            <meta name="description" content={t('meta.description')} />
          </Helmet>
          <Header className={styles.header} />
          <GalaxyBackground className={styles.background} />
          <StarLayer className={styles.stars} />
          <div className={styles.bladeContainer}>
            <TitleBlade />
            <VideoBlade />
            <SoftwareBlade />
            <SalesBlade />
            <CompellingBlade />
            <FullServiceBlade />
            <AdvantageBanner className={styles.marginAdjust} />
            <AdvantageBlade />
            <SuccessfulBlade className={styles.marginAdjust} />
            <StarterBlade className={styles.marginAdjust} />
          </div>
        </Layout>
      </div>
    );
  }
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translatedConnect(Page);

import React from 'react';
import PropTypes from 'prop-types';
import { siteConfig } from '../../constants/config';
/**
 * Intended to maintain a max width when the site is viewed on desktop
 */
const MaxWidthWrapper = ({ children, className, maxWidth }) => {
  const style = {
    maxWidth,
    margin: '0 auto',
    width: '100%',
  };
  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
};

MaxWidthWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  maxWidth: PropTypes.string,
};

MaxWidthWrapper.defaultProps = {
  className: '',
  maxWidth: siteConfig.maxContentWidth,
};

export default MaxWidthWrapper;

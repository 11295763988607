import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { translatedConnect } from '../../lib/utils';
import styles from './AdvantageInfluence.module.css';

const AdvantageInfluence = ({
  percentText, influenceText, t, className,
}) => (
  <Grid fluid className={cx(className, styles.root)}>
    <Row className={cx('no-gutters', styles.percentRow)} center="xs" middle="xs">
      <Col xs={6}>
        <h4>{percentText}</h4>
      </Col>
      <Col xs={6} className={styles.consumerCol}>
        <p className={styles.consumers}>{t('advantageBlade.report.consumers')}</p>
        <p className={styles.surveyed}>{t('advantageBlade.report.surveyed')}</p>
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <div className={styles.influenceContainer}>
          <h3 dangerouslySetInnerHTML={{ __html: influenceText }} />
        </div>
      </Col>
    </Row>
  </Grid>
);

AdvantageInfluence.propTypes = {
  className: PropTypes.string,
  percentText: PropTypes.string.isRequired,
  influenceText: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

AdvantageInfluence.defaultProps = {
  className: '',
};

export default translatedConnect(AdvantageInfluence);

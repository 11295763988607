import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { translatedConnect } from '../../lib/utils';
import styles from './AdvantageBlade.module.css';
import MaxWidthWrapper from '../MaxWidthWrapper';
import AdvantageInfluence from '../AdvantageInfluence';
import AdvantageReason from '../AdvantageReason';

const AdvantageBlade = ({ t, className }) => {
  const imageSrcs = [
    '/images/img_SalesCouple@2x.png',
    '/images/svg_convert.svg',
    '/images/svg_upsell.svg',
    '/images/svg_satisfy.svg',
  ];

  return (
    <MaxWidthWrapper className={cx(styles.root, className)}>
      <Grid fluid>
        <Row className={styles.rowStyle} center="xs" middle="xs">
          <Col xs={12} md={4}>
            <AdvantageInfluence
              percentText={t('advantageBlade.report.percentage1')}
              influenceText={t('advantageBlade.report.descision')}
            />
          </Col>
          <Col xs={12} md={4} className="last-md first-xs">
            <img src={imageSrcs[0]} className={styles.salesImage} alt="Sales Couple" />
          </Col>
          <Col xs={12} md={4} className="last-xs">
            <AdvantageInfluence
              percentText={t('advantageBlade.report.percentage2')}
              influenceText={t('advantageBlade.report.expensive')}
            />
          </Col>
        </Row>
        <Row className={styles.rowStyle}>
          <Col xs={12} md={4}>
            <AdvantageReason
              imageSrc={imageSrcs[1]}
              title={t('advantageBlade.badge1.header')}
              body={t('advantageBlade.badge1.body')}
            />
          </Col>
          <Col xs={12} md={4}>
            <AdvantageReason
              imageSrc={imageSrcs[2]}
              title={t('advantageBlade.badge2.header')}
              body={t('advantageBlade.badge2.body')}
            />
          </Col>
          <Col xs={12} md={4}>
            <AdvantageReason
              imageSrc={imageSrcs[3]}
              title={t('advantageBlade.badge3.header')}
              body={t('advantageBlade.badge3.body')}
            />
          </Col>
        </Row>
      </Grid>
    </MaxWidthWrapper>
  );
};

AdvantageBlade.propTypes = {
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
};

AdvantageBlade.defaultProps = {
  className: '',
};

export default translatedConnect(AdvantageBlade);

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { translatedConnect } from '../../lib/utils';
import styles from './FullServiceBadge.module.css';

const FullServiceBadge = ({
  imgSrc, text, link, t,
}) => (
  <div className={cx(styles.root)}>
    <img className={cx(styles.image)} src={imgSrc} alt="Promotion Badge" />
    <h4 className={cx(styles.text)}>{text}</h4>
    <div className={cx(styles.linkContainer)}>
      <a href={link} rel="noopener noreferrer" target="_blank">{t('link.promotion')}</a>
    </div>
  </div>
);

FullServiceBadge.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default translatedConnect(FullServiceBadge);

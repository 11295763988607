import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Grid, Row, Col } from 'react-flexbox-grid';
import styles from './VideoBlade.module.css';
import MaxWidthWrapper from '../MaxWidthWrapper';
import GetStartedButton from '../GetStartedButton';

const VideoBlade = ({ className }) => (
  <MaxWidthWrapper className={cx(styles.root, className)}>
    <Grid fluid>
      <Row>
        <Col xs={12}>
          <div className={styles.videoContainer}>
            <div className={styles.embed}>
              <iframe
                src="https://player.vimeo.com/video/414916364"
                title="ISAP Video"
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <GetStartedButton />
        </Col>
      </Row>
    </Grid>
  </MaxWidthWrapper>
);

VideoBlade.propTypes = {
  className: PropTypes.string,
};

VideoBlade.defaultProps = {
  className: '',
};

export default VideoBlade;

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { translatedConnect } from '../../lib/utils';
import styles from './SalesBlade.module.css';
import MaxWidthWrapper from '../MaxWidthWrapper';
import GetStartedButton from '../GetStartedButton';

const SalesBlade = ({ t, className }) => {
  const imgSrc = '/images/img_salesTool@2x.png';
  return (
    <MaxWidthWrapper className={cx(styles.root, className)}>
      <Grid fluid>
        <Row>
          <Col xs={12} md={6}>
            <img src={imgSrc} alt="Sales Tool" />
          </Col>
          <Col xs={12} md={6} className={cx(styles.textColumn)}>
            <div>
              <h3>{t('salesBlade.header')}</h3>
              <ul>
                <li>{t('salesBlade.item1')}</li>
                <li>{t('salesBlade.item2')}</li>
                <li>{t('salesBlade.item3')}</li>
                <li>{t('salesBlade.item4')}</li>
              </ul>
              <GetStartedButton />
            </div>
          </Col>
        </Row>
      </Grid>
    </MaxWidthWrapper>
  );
};

SalesBlade.propTypes = {
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
};

SalesBlade.defaultProps = {
  className: '',
};

export default translatedConnect(SalesBlade);

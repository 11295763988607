import React from 'react';
import PropTypes from 'prop-types';
import { translatedConnect } from '../../lib/utils';

const onClick = () => {
  window.scrollTo({
    top: document.body.scrollHeight,
    left: 0,
    behavior: 'smooth',
  });
};

const GetStartedButton = ({ className, t }) => (
  <button className={className} type="button" onClick={onClick}>{t('link.start')}</button>
);

GetStartedButton.propTypes = {
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
};

GetStartedButton.defaultProps = {
  className: '',
};

export default translatedConnect(GetStartedButton);

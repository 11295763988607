import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { translatedConnect } from '../../lib/utils';
import styles from './AdvantageBanner.module.css';
import MaxWidthWrapper from '../MaxWidthWrapper';

const AdvantageBanner = ({ t, className }) => (
  <div className={cx(styles.root, className)}>
    <MaxWidthWrapper className={styles.container}>
      <h3>{t('banner.sales')}</h3>
    </MaxWidthWrapper>
  </div>
);

AdvantageBanner.propTypes = {
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
};

AdvantageBanner.defaultProps = {
  className: '',
};

export default translatedConnect(AdvantageBanner);

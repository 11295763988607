import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { translatedConnect } from '../../lib/utils';
import styles from './StarterBlade.module.css';

const onClick = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};

const StarterBlade = ({ t, className }) => (
  <Grid fluid className={cx(styles.root, className)}>
    <Row>
      <Col xs={12}>
        <h1>{t('startBlade.header')}</h1>
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <p style={{ textTransform: 'uppercase' }}>{t('startBlade.text')}</p>
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <div className={cx(styles.linkContainer)}>
          <a href="mailto:IntelSAP.info@tgahelp.zendesk.com?subject=Intel Software Advantage Program - Info Request">{t('startBlade.address')}</a>
        </div>
      </Col>
    </Row>
    <Row className={cx(styles.arrow)}>
      <Col xs={12}>
        <button type="button" onClick={onClick}>{t('startBlade.top')}</button>
      </Col>
    </Row>
  </Grid>
);

StarterBlade.propTypes = {
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
};

StarterBlade.defaultProps = {
  className: '',
};

export default translatedConnect(StarterBlade);

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import MaxWidthWrapper from '../MaxWidthWrapper';
import styles from './Header.module.css';

const Header = ({ className }) => (
  <div className={cx(className, styles.root)}>
    <MaxWidthWrapper className={styles.content}>
      <img src="/images/svg_isap_logo.svg" alt="Intel Logo" />
    </MaxWidthWrapper>
  </div>
);

Header.propTypes = {
  className: PropTypes.string,
};

Header.defaultProps = {
  className: '',
};
export default Header;

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './AdvantageReason.module.css';

const AdvantageReason = ({
  title, body, imageSrc, className,
}) => (
  <div className={cx(className, styles.root)}>
    <img src={imageSrc} alt="Sales Icon" />
    <h4>{title}</h4>
    <p dangerouslySetInnerHTML={{ __html: body }} />
  </div>
);

AdvantageReason.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
};

AdvantageReason.defaultProps = {
  className: '',
};

export default AdvantageReason;

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { translatedConnect } from '../../lib/utils';
import styles from './FullServiceBlade.module.css';
import MaxWidthWrapper from '../MaxWidthWrapper';
import FullServiceBadge from '../FullServiceBadge';
import GetStartedButton from '../GetStartedButton';

const FullServiceBlade = ({ t, className }) => {
  const badgeImageSrcs = [
    '/images/img_ISV_FeelTheForce@2x.png',
    '/images/img_ISV_ElevateYourGame@2x.png',
    '/images/img_ISV_LegendToTheCore@2x.png',
    '/images/img_ISV_ChampionToTheCore@2x.png',
  ];
  const badgeLinks = [
    'https://softwareoffer.intel.com/offer/20q1-06/learnmore',
    'https://softwareoffer.intel.com/offer/20Q2-09/learnmore',
    'https://softwareoffer.intel.com/offer/20Q1-07/learnmore',
    'https://softwareoffer.intel.com/offer/20Q2-08/learnmore',
  ];
  return (
    <MaxWidthWrapper className={cx(styles.root, className)}>
      <Grid fluid>
        <Row className={cx(styles.rowStyle)}>
          <Col xs={12} md={4} className="last-xs first-md">
            <FullServiceBadge className={styles.badgeColumn} imgSrc={badgeImageSrcs[0]} text={t('fullServiceBlade.badge1')} link={badgeLinks[0]} />
          </Col>
          <Col xs={12} md={8} className={cx(styles.textColumn)}>
            <div>
              <h3>{t('fullServiceBlade.header')}</h3>
              <ul>
                <li>{t('fullServiceBlade.item1')}</li>
                <li>{t('fullServiceBlade.item2')}</li>
                <li>{t('fullServiceBlade.item3')}</li>
                <li>{t('fullServiceBlade.item4')}</li>
                <li>{t('fullServiceBlade.item5')}</li>
              </ul>
              <GetStartedButton />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <FullServiceBadge className={styles.badgeColumn} imgSrc={badgeImageSrcs[1]} text={t('fullServiceBlade.badge2')} link={badgeLinks[1]} />
          </Col>
          <Col xs={12} md={4}>
            <FullServiceBadge className={styles.badgeColumn} imgSrc={badgeImageSrcs[2]} text={t('fullServiceBlade.badge3')} link={badgeLinks[2]} />
          </Col>
          <Col xs={12} md={4}>
            <FullServiceBadge className={styles.badgeColumn} imgSrc={badgeImageSrcs[3]} text={t('fullServiceBlade.badge4')} link={badgeLinks[3]} />
          </Col>
        </Row>
      </Grid>
    </MaxWidthWrapper>
  );
};

FullServiceBlade.propTypes = {
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
};

FullServiceBlade.defaultProps = {
  className: '',
};

export default translatedConnect(FullServiceBlade);
